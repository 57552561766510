<template>
  <header class="header-menu-area bg-white">
    <div class="div">
      <div class="div-2">
        <div class="div-7">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3b510d59a1ed70c1e7ee15ffabd9028c31e1e102e50687cf6079d4e0b5e627c9?"
            class="img-5"
          />
          <div class="div-9">الجوال 9359 338 55 966+</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/8f1a769d3370cfcbdde852c6734caa039602422058dc8c3cebe2d7a1589af884?"
            class="img-4"
          />
          <div class="div-8">الباحة – محافظة المندق</div>
        </div>
        <div class="div-3">
          <div class="div-6">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/bbe1552fc96e56d9776ddcd238b300a8dd1acd844e7783e4e827e8806f65af44?"
              class="img"
            /><img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/40c1b2c4989d1cfbc98731a1c650041982fc77b1d1e7ed75c912da4806646d0f?"
              class="img"
            /><img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/6cdcb8ed144fc646d4216dffc4122c302ed907641cdff34d5be6552a014975be?"
              class="img-2"
            /><img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/337de5f6b32988df341498a6fa848f7ba25df1e324d02393d5f400f1808c2a12?"
              class="img-3"
            /><img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/09cb9c8b42408f9a4a3a5aa03f22c7acb6684cba8242811840ae9ba4e8854205?"
              class="img"
            /><img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/d8159b6098c362b45065a1e4c2a17ad8bc664deb95282340eb35e9eb5e55934f?"
              class="img"
            />
          </div>
          <div class="log">
            <div class="div-4">
              <router-link to="/login">تسجيل الدخول</router-link>
            </div>
            <div class="div-5">
              <router-link to="/register"> إنشاء حساب</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end header-top -->
    <div class="header-menu-content pr-150px pl-150px bg-white">
      <div class="container">
        <div class="main-menu-content">
          <a href="#" class="down-button"><i class="la la-angle-down"></i></a>
          <div class="row align-items-center">
            <div class="col-lg-2">
              <div class="logo-box">
                <a href="#" class="logo"
                  ><img
                    src="../assets/img/logo.png"
                    alt="logo"
                    height="90"
                    width="90"
                /></a>
                <div class="user-btn-action">
                  <div
                    class="search-menu-toggle icon-element icon-element-sm shadow-sm mr-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Search"
                  >
                    <i class="la la-search"></i>
                  </div>
                  <div
                    class="off-canvas-menu-toggle cat-menu-toggle icon-element icon-element-sm shadow-sm mr-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Category menu"
                  >
                    <i class="la la-th-large"></i>
                  </div>
                  <div
                    class="off-canvas-menu-toggle main-menu-toggle icon-element icon-element-sm shadow-sm"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Main menu"
                  >
                    <i class="la la-bars"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- end col-lg-2 -->
            <div class="col-lg-10">
              <div class="menu-wrapper">
                <nav class="main-menu font-weight-bold">
                  <ul>
                    <li>
                      <router-link
                        to="/"
                        aria-controls="aboutus"
                        aria-selected="false"
                      >
                        الرئيسية</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        to="/cart"
                        aria-controls="services"
                        aria-selected="false"
                        >عربة التسوق</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/"> إهداء لمساجد مكة</router-link>
                    </li>
                    <li>
                      <router-link
                        to="/about-us"
                        aria-controls="policy"
                        aria-selected="false"
                      >
                        أعمالنا</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/contact-us"> اتصل بنا</router-link>
                    </li>
                    <li>
                      <router-link to="/profile"> الملف الشخصي</router-link>
                    </li>
                  </ul>
                  <!-- end ul -->
                </nav>
                <!-- end nav-right-button -->
              </div>
              <!-- end menu-wrapper -->
            </div>
            <!-- end col-lg-10 -->
          </div>
          <!-- end row align-items-center -->
        </div>
        <!-- end main-menu-content -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end header-menu-content -->
  </header>
</template>

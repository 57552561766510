import { createApp } from "vue";
import App from "./App.vue";
import jquery from "jquery";
import "bootstrap";
import router from "./router/index";
import Vue3CountryIntl from "vue3-country-intl";
// import css
import "vue3-country-intl/lib/vue3-country-intl.css";
const app = createApp(App);
app.use(jquery);
app.component(Vue3CountryIntl.name, Vue3CountryIntl);
app.use(router).mount("#app");

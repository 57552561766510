import { createRouter, createWebHistory } from "vue-router";
// import Router from 'vue-router'
const routes = [
  {
    path: "/",
    name: "Home",
    alias: "/home",
    component: () => import("../components/Home"),
    meta: {
      title: "الرئيسية",
      page: "home",
    },
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import("../components/Cart"),
    meta: {
      title: "عربة التسوق",
      page: "Cart",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../components/Profile"),
    meta: {
      title: "الملف الشخصي",
      page: "Profile",
    },
  },
  {
    path: "/contact-us",
    name: "contact us",
    component: () => import("../components/contact-us"),
    meta: {
      title: " اتصل بنا",
      page: "contact-us",
    },
  },
  {
    path: "/about-us",
    name: "about us",
    component: () => import("../components/About"),
    meta: {
      title: "أعمالنا",
      page: "About",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../components/Login"),
    meta: {
      title: "تسجيل الدخول",
      page: "Login",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../components/Register"),
    meta: {
      title: " إنشاء حساب",
      page: "Register",
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../components/terms-conditions"),
    meta: {
      title: " البنود والظروف",
      page: "terms",
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../components/privacy-policy"),
    meta: {
      title: " سياسة الخصوصية",
      page: "terms",
    },
  },
];
const router = createRouter({
  // linkActiveClass: 'active',
  // linkExactActiveClass: 'active',
  history: createWebHistory(),
  routes,
});
export default router;

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});
